import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled, useTheme } from '@mui/material/styles';
import { useMediaQuery, Divider } from '@mui/material';
import { Topbar, Footer, Sidebar } from './components';

const Root = styled('div')(({ theme }) => ({
  height: '100%',
}));

const Main = ({ children, themeToggler, themeMode }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const pages = {
    landings: {
      title: 'Services',
      id: 'services',
      children: {
        services: {
          groupTitle: 'Services',
          pages: [
            {
              title: 'Offerings',
              href: '/solution-offering',
            },
            {
              title: 'Business Transformation',
              href: '/solution-offering/#STRATEGY',
            },
            {
              title: 'Solution',
              href: '/solution-offering/#SOLUTION',
            },
            {
              title: 'Delivery',
              href: '/solution-offering/#DELIVERY',
            },
          ],
        },
      },
    },
    about: {
      title: 'About',
      id: 'about',
      children: {
        company: {
          groupTitle: 'About Us',
          pages: [
            {
              title: 'About Us',
              href: '/about/#about-us',
            },
            {
              title: 'Our Story',
              href: '/about/#about-story',
            },
            // {
            //   title: 'Our Team',
            //   href: '/about/#about-team',
            // },
            // {
            //   title: 'Core Values',
            //   href: '/#home-core_values',
            // },
            {
              title: 'Contact Details',
              href: '/about/#about-contact',
            },
          ],
        },
      },
    },
    legal: {
      title: 'Policies',
      id: 'policies',
      children: {
        policies: {
          groupTitle: 'Policies',
          pages: [
            {
              title: 'Privacy Policy',
              href: '/policy/privacy',
            }
          ]
        }
      }
    }
  };

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const open = isMd ? false : openSidebar;

  return (
    <Root>
      <Topbar 
        onSidebarOpen={handleSidebarOpen} 
        pages={pages} 
        themeMode={themeMode} 
        themeToggler={themeToggler} 
      />
      <Sidebar
        onClose={handleSidebarClose}
        open={open}
        variant="temporary"
        pages={pages}
      />
      <main>
        <Divider />
        {children}
      </main>
      <Footer pages={pages} />
    </Root>
  );
};

Main.propTypes = {
  children: PropTypes.node,
  themeToggler: PropTypes.func.isRequired,
  themeMode: PropTypes.string.isRequired,
};

export default Main;
