import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { styled } from '@mui/system';
import {
  Typography,
  IconButton,
  Grid2 as Grid,
  List,
  ListItem,
} from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import PinterestIcon from '@mui/icons-material/Pinterest';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

import { Image } from 'components/atoms';

// Styled components
const Root = styled('div')(({ theme }) => ({
  padding: theme.spacing(6, 0),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(12, 0),
  },
  background: theme.palette.background.footer,
}));

const FooterContainer = styled('div')(({ theme }) => ({
  maxWidth: theme.layout.contentWidth,
  width: '100%',
  margin: '0 auto',
  padding: theme.spacing(0, 2),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(0, 8),
  },
}));

const LogoContainerItem = styled(ListItem)({
  paddingTop: 0,
});

const LogoContainer = styled('div')({
  width: 180,
});

const LogoImage = styled('img')({
  width: '100%',
  height: '100%',
});

const LogoImageB = styled('img')({
  width: 'auto',
  height: '100%',
});

const GroupTitle = styled(Typography)(({ theme }) => ({
  textTransform: 'uppercase',
  color: theme.palette.primary.dark,
  marginBottom: theme.spacing(1),
}));

const SocialIcon = styled(IconButton)(({ theme }) => ({
  padding: 0,
  marginRight: theme.spacing(2),
  color: 'rgba(255,255,255,.6)',
  '&:hover': {
    background: 'transparent',
  },
  '&:last-child': {
    marginRight: 0,
  },
}));

const Icon = styled('div')(({ theme }) => ({
  fontSize: 24,
}));

const MenuListContainer = styled('div')({
  padding: '0 !important',
});

const Menu = styled('div')({
  display: 'flex',
});

const MenuItem = styled(ListItem)(({ theme }) => ({
  margin: theme.spacing(2),
  '&:last-child': {
    marginBottom: 0,
  },
}));

const MenuGroupItem = styled(ListItem)(({ theme }) => ({
  paddingTop: 0,
  paddingBottom: theme.spacing(0.5),
  '&:last-child': {
    paddingBottom: 0,
  },
}));

const MenuGroupTitle = styled(Typography)({
  textTransform: 'uppercase',
  color: 'white',
});

const NavLink = styled(Typography)({
  color: 'rgba(255,255,255,.6)',
});

/**
 * Footer component
 *
 * @param {Object} props
 */
const Footer = props => {
  const { pages, className, ...rest } = props;

  const landings = pages.landings;
  const about = pages.about;
  const legal = pages.legal;

  const MenuGroup = ({ item }) => (
    <List disablePadding>
      <MenuGroupItem disableGutters>
        <MenuGroupTitle variant="body2">
          {item.groupTitle}
        </MenuGroupTitle>
      </MenuGroupItem>
      {item.pages.map((page, i) => (
        <MenuGroupItem disableGutters key={i}>
          <NavLink
            variant="body2"
            component="a"
            href={page.href}
          >
            {page.title}
          </NavLink>
        </MenuGroupItem>
      ))}
    </List>
  );

  

  const LandingPages = () => {
    const { services } = landings.children;
    return (
      <Menu>
        <MenuGroup item={services} />
      </Menu>
    );
  };

  const AboutPages = () => {
    const { company } = about.children;
    return (
      <Menu>
        <MenuGroup item={company} />
      </Menu>
    );
  };

  const PoliciesPages = () => {
    const { policies } =  legal.children;
    return (
      <Menu>
        <MenuGroup item={policies} />
      </Menu>
    );
  };

  return (
    <Root className={clsx(className)} {...rest}>
      <FooterContainer>
        <Grid container spacing={4}>
          <Grid item size={{xs:12, md:4}}>
            <List disablePadding>
              <Typography>Powered By</Typography>
              <LogoContainerItem disableGutters>
                <LogoContainer>
                  <a href="https://mindsclik.com" title="MindsClik">
                    <LogoImage
                      src="/assets/logos/logo_redwhite.png"
                      alt="MindsClik"
                    />
                  </a>
                </LogoContainer>  & 
                <LogoContainer>
                  <a href="https://www.linkedin.com/company/gargit" title="MindsClik">
                    <LogoImageB
                      src="/assets/logos/gargi.png"
                      alt="MindsClik"
                    />
                  </a>
                </LogoContainer>
              </LogoContainerItem>
              <ListItem disableGutters>
                <SocialIcon href="https://linkedin.com/company/mindsclik" target="_blank">
                  <LinkedInIcon />
                </SocialIcon>
                <SocialIcon href="https://www.facebook.com/mindsclik" target="_blank">
                  <FacebookIcon />
                </SocialIcon>
                <SocialIcon>
                  <InstagramIcon />
                </SocialIcon>
                <SocialIcon href="https://www.twitter.com/mindsclik" target="_blank">
                  <TwitterIcon />
                </SocialIcon>
                {/* <SocialIcon>
                  <PinterestIcon />
                </SocialIcon> */}
              </ListItem>
            </List>
          </Grid>
          <Grid item size={{xs:12, md:8}} component={MenuListContainer}>
            <Grid container spacing={0}>
            <Grid item>
                <AboutPages />
              </Grid>
              <Grid item>
                <PoliciesPages />
              </Grid>
              {/* <Grid item>
                <LandingPages />
              </Grid>
              <Grid item>
                <AboutPages />
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </FooterContainer>
    </Root>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.object.isRequired,
};

export default Footer;
